
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './App.css';
import arrowTop from './assets/arrow-top.png'
import { interpolate } from 'd3-interpolate'
import Spline from '@splinetool/react-spline';
import loading from './components/loading.gif';
import Hero from './components/Hero';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const Contact = React.lazy(() => import('./components/Contact'));
const MobileNavbar = React.lazy(() => import('./components/MobileNavbar'));
const Navbar = React.lazy(() => import('./components/Navbar'));
const Offer = React.lazy(() => import('./components/Offer'));
const Portfolio = React.lazy(() => import('./components/Portfolio'));
const Process = React.lazy(() => import('./components/Process'));
const Packages = React.lazy(() => import('./components/Packages'));


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDuUfYhjJ3ptXKjNEgKjBqIUAnaU7Wv_ys",
  authDomain: "webagency-c81c0.firebaseapp.com",
  projectId: "webagency-c81c0",
  storageBucket: "webagency-c81c0.appspot.com",
  messagingSenderId: "968434254686",
  appId: "1:968434254686:web:884cf87593b122637805cf",
  measurementId: "G-DR323FGWJV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  const [showLoading, setShowLoading] = useState(true);
  const [showIcon, setShowIcon] = useState(false);
  const [cubeTrack, setCubeTrack] = useState([]);
  const splineRef = useRef();
  

  function onLoad(spline) {
    splineRef.current = spline.findObjectById('b9c046a8-f055-4b01-a6b8-fada8a0e939e');
    try {

      if (splineRef.current.position.x !== undefined && window.innerWidth > 780 ) {
        splineRef.current.position.x = -window.innerWidth
        splineRef.current.position.z = -window.innerWidth * 2
        console.log('x and y set to width')
      } else if (window.innerWidth < 780 && splineRef.current.position.x !== undefined ) {
        splineRef.current.position.x = -window.innerHeight
        splineRef.current.position.z = 0
        splineRef.current.position.y = 0
        console.log('x and y set to 0')
      }
      } catch {
      console.log("Spline loading ...")
    }
  }


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoading(false);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);   

  useLayoutEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width > 780) {
      setCubeTrack([
        { x: -width, y: 0, z: -width * 2 },
        { x: -width * 4, y: 0.05*height, z: 0 },
        { x: -2500, y: 0, z: width },
        { x: -3000, y: 0, z: -width * 0.75 },
      ]);
    } else {
      setCubeTrack([
        { x: -width, y: 0, z: 0 },
        { x: -2500, y: 0, z: 0 },
        { x: -2500, y: 0, z: width },
        { x: -3000, y: 0, z: -width * 0.75 },
      ]);
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount
  
  useEffect(() => {
    const calculateInterpolation = () => {
      const scrollPosition = window.scrollY;
      const progress = Math.min(1, scrollPosition / window.innerHeight);
      return interpolate(cubeTrack[0], cubeTrack[1])(progress);
    };
    console.log(cubeTrack)
    const updateCubePosition = () => {
      try {
        if (splineRef.current && splineRef.current.position && splineRef.current.position.x !== undefined) {
          const interpolatedValues = calculateInterpolation();
          splineRef.current.position.x = interpolatedValues.x;
          splineRef.current.position.y = interpolatedValues.y;
          splineRef.current.position.z = interpolatedValues.z;
        }
      } catch (error) {
        console.error('Error updating cube position:', error);
      }
    };
  
    const handleScroll = () => {
      updateCubePosition();
    };
  
    window.addEventListener('scroll', handleScroll);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cubeTrack, splineRef]);
  
  
    
  


  return (
    <div className='bg-black relative z-20 overflow-clip'>
      {showLoading && 
      <div className='w-screen h-screen bg-black absolute z-[1000] flex items-center justify-center overflow-clip'>
        <img alt='loading ...' src={loading} ></img>
        </div>}
        <div className='fixed z-10 right-0 left-0 w-screen h-screen'>
          <Spline onLoad={onLoad} scene="https://prod.spline.design/B0YTx8-fWS1zJ31B/scene.splinecode" />
        </div>
              
          {!showLoading && 
          <> 
          <Tooltip id="enquiry" className='relative z-50' />
          <a data-tooltip-id="enquiry" 
            data-tooltip-content="Takes 2 minutes"
            className='z-[9999] bottom-0 right-0 fixed m-5 flex items-center justify-center text-6xl overscroll-none wave' 
            href="https://tally.so/r/wg99xN#tally-open=wg99xN&tally-layout=modal&tally-width=1100&tally-emoji-text=👋&tally-emoji-animation=wave"
            >
            👋
          </a> 

          <Navbar />
          <MobileNavbar />
            <Hero />
            <div className='backdrop-blur-xl relative z-20'>
              <Offer />
              <Portfolio />
              <Process />
              <Packages />
              <Contact />
            </div>

          {showIcon && (
            <div className={`fixed cursor-pointer bottom-4 right-4 ${showIcon ? 'animate shown' : 'animate'}`}>
              <img
                src={arrowTop}
                alt='scroll top'
                width={64}
                height={64}
                className='colourfilter animate-bounce rounded-full'
              />
            </div>
          )}</>
        }
         
     
      
    </div>
  );
}

export default App;
