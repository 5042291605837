import React from 'react';

function Hero() {


  return (
   
     
       
          <div id='home' className={`section h-screen w-screen relative flex mx-auto z-20`}>
            <div className='flex flex-col mx-5 md:mx-0 gap-4 text-white text-center items-start md:left-[10%] justify-center md:z-10 md:absolute w-full md:w-1/2 my-auto top-0 bottom-0'>
              <h1 className='font-bold text-5xl md:text-8xl mx-auto md:mx-0 text-center md:text-start'>Custom <br className='hidden md:block'></br> Website <br></br> For Any Budget</h1>
              <p className='text-xl md:text-2xl text-center md:text-start max-w-xl mx-auto md:mx-0'>Free Custom built websites which are uniquely yours. We'll build your website completely for free + if you already have a website, We'll match your current hosting fee.</p>
              <a className='text-xl md:text-2xl rounded-xl bg-wisteria px-3 py-2 font-bold hover:scale-105 transition-all duration-300 active:translate-y-1 mx-auto md:mx-0' href='#process'>See How We Work</a>
            </div>
          </div>
       
       
    
  );
}

export default Hero;
